<template>
  <div class="content-area">
    <hero-bar>
      {{ $t('addresses') }}
      <b-button
        v-if="checkPermission('address_create')"
        id="new-address"
        slot="right"
        tag="router-link"
        :to="{ name: 'addresses.create'}"
        icon-left="plus-circle"
        type="is-link"
      >
        {{ $t('createAddress') }}
      </b-button>
    </hero-bar>

    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced bg-none">
        <addresses-table />
      </card-component>
    </section>
  </div>
</template>

<script>
export default {
  title: ({ $i18n }) => $i18n.t('addresses'),
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    AddressesTable: () => import('@/components/AddressesTable'),
  },
}
</script>
